import HowBecomeAMember from "components/Homepage/HowBecomeAMember";
import HowToConfirmMembership from "components/Homepage/HowToConfirmMembership";
import MembershipAdvantages from "components/Homepage/MembershipAdvantages";
import Support from "components/Homepage/Support";
import WhoBecomeAMember from "components/Homepage/WhoBecomeAMember";
import React, { useEffect } from "react";
import { useGloabalStore } from "store/GlobalStore";

function Homepage() {


  return (
    <div className='flex flex-col space-y-6 bg-background '>
      <div className="w-full flex justify-center pt-10">
        <img src="/partner_logo.png" alt="partner_logo" width={382} height={176} />
      </div>
      <MembershipAdvantages />
      <WhoBecomeAMember />
      <HowBecomeAMember />
      <HowToConfirmMembership />
      <Support />
    </div>
  )
}

export default Homepage;
