import React from "react";
import { Toaster } from "react-hot-toast";
import RouterList from "routerList";



function App() {

  return (
    <div>
      <RouterList />
      <Toaster />
    </div>
  );
}

export default App;
