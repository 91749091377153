
function MembershipAdvantages() {
  return (
    <div className="white-container">
      <div className="pb-6">
        <h3 className="text-3xl font-bold text-title">SACHA PARTNER Mobil Uygulaması ile <br /> Partner Üye Olmanın Avantajlarını Keşfedin!</h3>
      </div>
      <div className="grid grid-cols-12">
        <div className="flex flex-col col-span-12 space-y-4 lg:col-span-5">
          <p className="text-xl font-semibold text-text">2011 yılından bu yana profesyonel markaların
            saç ve cilt bakım ürünlerini bulabileceğiniz
            Sachane.com artık yepyeni mobil Uygulaması
            SachaPartner ile üye olan kurumsal partnerlerine
            avantajlı fiyatlar ve fırsatlar sunacak!
          </p>
          <p className="text-xl font-semibold text-text">
            Uygulamayı indirip üyelik bilgilerinizi girdikten sonra
            vergi levhanızı ve üyelik açtığınız e-mail adresini
            <a href="mailto: bilgi@sachane.com" className="ml-1 underline text-title">bilgi@sachane.com</a> adresine iletebilirsiniz.
          </p>
          <p className="text-xl font-semibold text-text">Üyeliğiniz iş günleri içerisinde <br />
            <span className="text-title">24 saat geçmeden onaylanır.</span><br />
            Ardından üyelik bilgilerinizle hemen uygulamaya
            giriş yapıp size sunulan tüm ayrıcalıklardan
            yararlanmaya başlayabilirsiniz.
          </p>
          <div className="flex flex-col items-center space-y-2 lg:space-y-0 lg:space-x-1 lg:flex-row">
            <a href="https://apps.apple.com/app/sachane/id6444608558" target="_blank" title="Sachane App Store Mobile Uygulama" rel="nofollow">
              <img src="/appstore.png" alt="App Store" width={303} height={80} />
            </a>
            <a href="https://play.google.com/store/apps/details?id=com.sachapartner.android" target="_blank" title="Sachane Google Play Store Mobile Uygulama" rel="nofollow">
              <img src="/googleplay.png" alt="Google Play" width={303} height={80} />
            </a>
          </div>
        </div>
        <div className="flex justify-center w-full col-span-12 lg:justify-end lg:col-span-7">
          <div>
          <img src="/advantagesman.png" alt="Advantages Man" width={540} height={553} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default MembershipAdvantages