
function Support() {
    return (
        <div className="white-container">
            <div className="pb-4">
                <h3 className="text-3xl font-bold text-title">Desteğe İhtiyacın Olduğu Her An Yanındayız!</h3>
            </div>
            <div className="grid items-center grid-cols-12">
                <div className="flex flex-col col-span-12 space-y-4 lg:col-span-8">
                    <p className="text-xl font-bold text-text">Tüm bilgilendirme ve destek hizmetlerinden yararlanmak için bize <span className="text-title">hafta içi 10:00 - 16:30 saatleri arasında 0850 307 70 70 numaralı çağrı merkezimizden ulaşabilirsiniz.</span> adresine isim-soyisim, email ve iletişim bilgilerini ekleyerek gönder</p>
                    <p className="text-xl font-bold text-text">Bununla birlikte uygulamadaki hesabım butonuna bastıktan <br /> sonra mesajınızı uygulama içerinden bize iletebilirsiniz.</p>
                </div>
                <div className="flex items-center justify-center col-span-12 lg:col-span-4">
                    <img src="/callcenter.png" alt="callcenter" width={249} height={247} />
                </div>
            </div>
        </div>
    )
}

export default Support