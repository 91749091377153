
function WhoBecomeAMember() {
  return (
    <div className="flex flex-col space-y-4">
      <h3 className="text-2xl font-bold text-title">Kimler Üye Olabilir?</h3>
      <div className="flex items-center space-x-2 lg:space-x-6">
        <div className="flex flex-col items-center space-y-1 text-center lg:space-y-2">
          <div className="h-full">
            <img src="/kuaforler.png" alt="Kuaförler" width={218} height={217} />
          </div>
          <div className="h-[80px] lg:h-auto">
            <p className="text-xl text-title">Kuaförler</p>
          </div>
        </div>
        <div className="flex flex-col items-center space-y-1 text-center lg:space-y-2">
          <div className="h-full">
            <img src="/guzelliksalonu.png" alt="Güzellik Salonları" width={218} height={218} />
          </div>
          <div className="h-[80px] lg:h-auto">
            <p className="text-xl text-title">Güzellik Salonları</p>
          </div>
        </div>
        <div className="flex flex-col items-center space-y-1 text-center lg:space-y-2">
          <div className="h-full">
            <img src="/tirnakguzellik.png" alt="Tırnak Güzellik Merkezleri" width={218} height={218} />
          </div>
          <div className="h-[80px] lg:h-auto">
            <p className="text-xl text-title" >Tırnak Güzellik Merkezleri</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default WhoBecomeAMember