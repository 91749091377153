import React, { Suspense, useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
} from "react-router-dom";
import { createBrowserHistory } from "history";
import Login from "pages/Login/Login";
import Loading from "pages/Loading/Loading";
import Layout from "layout/Layout";
import { PrivateRoute } from "private.routes";
import Homepage from "pages/Homepage/Homepage";
import Page404 from "pages/page404/Page404";

const browserHistory = createBrowserHistory();

const RouterList = () => {
  const [authTokens, setAuthTokens] = useState();
  const setTokens = (data) => setAuthTokens(data);

  //   const [isLoggin, setIsLoggin] = useAuthStore((state) => [
  //     state.isLoggin,
  //     state.setIsLoggin,
  //   ]);

  //   const checkAuth = async () => {
  //     if (localStorage.getItem("__mp_tk") !== null) {
  //       tokenCheck(localStorage.getItem("__mp_tk"))
  //         .then((res) => {
  //           console.log(res);
  //           if (!res.status) {
  //             setIsLoggin(false);
  //             history.push("/login");
  //           }
  //         })
  //         .catch((err) => {
  //           setIsLoggin(false);
  //           history.push("/login");
  //         });
  //     }
  //   };

  //   useEffect(() => {
  //     checkAuth();
  //   }, []);

  return (
    <Router>
      <Suspense fallback={<Loading />}>
        <Routes>
          {/* <Route
            path="/login"
            element={<Login />}
          /> */}
          <Route path="*" element={<Page404/>} />
            <Route element={<Layout />}>
              <Route
                path="/"
                element={<Homepage/>}
              />
            </Route>
        </Routes>
      </Suspense>
    </Router>
  );
};
export default RouterList;
