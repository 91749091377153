
function HowToConfirmMembership() {
  return (
    <div className="white-container">
      <div className="pb-4">
        <h3 className="text-3xl font-bold text-title">Üyeliğin Nasıl Onaylanır?</h3>
      </div>
      <div className="flex flex-col pb-10 space-y-4 lg:w-8/12">
        <p className="text-xl font-bold text-text">Mobil uygulamada bilgilerini girdikten sonra, firmanıza ait vergi levhasını <a href="mailto: bilgi@sachane.com" className="text-title">bilgi@sachane.com</a> adresine isim-soyisim, email ve iletişim bilgilerini ekleyerek gönder</p>
        <p className="text-xl font-bold text-text">Üyeliğin, gerekli koşulların sağlanması durumunda 24 saat içerisinde onaylanacaktır. </p>
        <p className="text-xl font-bold text-text">Şimdi fırsatlardan yararlanma zamanı!</p>
      </div>
      <div className="pb-4">
        <h3 className="text-3xl font-bold text-title">SachaPartner Mobil Uygulamada Seni Neler Bekliyor!</h3>
      </div>
      <div className="flex flex-col pb-10 space-y-4 lg:w-8/12">
        <p className="text-xl font-bold text-text">Sachane.com veya Sachane mobil uygulaması ile temelde aynı özelliklere sahip olan SachaPartner uygulamasını kullanmak çok kolay. Bu uygulamada perakende müşterilerimize sunduğumuzdan çok daha avantajlı bir alışveriş deneyimi sunuyoruz.</p>
        <p className="text-xl font-bold text-title">SachaPartner kurumsal üye olmanın ayrıcalığı ile en uygun fiyatlarla alışveriş yapabilir, <br/> sipariş oluşturabilir, avantajlı set fiyatlarından ve sana özel kampanya tekliflerinden yararlanabilirsin.</p>
      </div>
    </div>
  )
}

export default HowToConfirmMembership