
function Footer() {
  return (
    <footer className="flex flex-col justify-between w-full py-8 space-y-4 lg:flex-row lg:space-y-0">
      <div className="grid w-full grid-cols-2 gap-4 lg:w-7/12">
        <div className="flex flex-col col-span-2 space-y-1 lg:col-span-1">
          <h3 className="text-xl font-semibold text-title">SachaPartner</h3>
          <a href="https://sachane.com/iletisim" title="İletişim" className="text-text">İletişim</a>
          {/* <a href="https://sachane.com/hesabim/musteri-hizmetleri" title="Müşteri Hizmetleri" className="text-text">Müşteri Hizmetleri</a> */}
          <a href="https://sachane.com/sirketimiz-hakkinda-a-2" title="Hakkımızda" className="text-text">Hakkımızda</a>
          <a href="https://sachane.com/banka-hesap-numaralari" title="Banka Hesap Numaraları" className="text-text">Banka Hesap Numaraları</a>
          <a href="https://sachane.com/hesabim/sik-sorulan-sorular" title="Sık Sorulan Sorular" className="text-text">Sık Sorulan Sorular</a>
        </div>
        <div className="flex flex-col col-span-2 space-y-1 lg:col-span-1">
          <h3 className="text-xl font-semibold text-title">Adres</h3>
          <p className="text-text">Halide Edip Adıvar Mahallesi <br />
            Akar Caddesi No:6 <br />
            Kat:-1 Şişli / İstanbul
          </p>
        </div>
        <div className="w-full col-span-2">
          <p className="text-text">2011-2023 MOT Grup Bilişim LTD. ŞTİ. - © Sachane </p>
        </div>
      </div>
      <div className="flex w-full lg:justify-end">
        <div>
          <img src="/sachanetbis.png" alt="etbis" width={258} height={71} />
        </div>
      </div>
    </footer>
  )
}

export default Footer