
function HowBecomeAMember() {
  return (
    <div className="white-container">
      <div className="pb-6">
        <h3 className="text-3xl font-bold text-title">Nasıl Üye Olunur?</h3>
      </div>
      <div className="flex flex-col space-y-8 ">
        <div className="relative flex items-center space-x-6">
          <img src="/asama_01.png" alt="Asama 1" width={90} height={90} />
          <p className="font-bold lg:text-xl text-text">Uygulamayı İndir</p>
          <div className="hidden lg:block h-[32px] border-title border-l-4 left-[18px] absolute -bottom-8 " />
        </div>
        <div className="relative flex items-center space-x-6">
          <img src="/asama_02.png" alt="Asama 2" width={90} height={90} />
          <p className="font-bold lg:text-xl text-text">İsim Soyisim, <br /> E-posta ve Şifre Bilgilerini Gir</p>
          <div className="hidden lg:block h-[32px] border-title border-l-4 left-[18px] absolute -bottom-8 " />
        </div>
        <div className="relative flex items-center space-x-6">
          <img src="/asama_03.png" alt="Asama 3" width={90} height={90} />
          <p className="font-bold lg:text-xl text-text">Kurum Bilgilerini ve <br /> Vergi Dairesi Bilgilerini Tamamla</p>
          <div className="hidden lg:block h-[32px] border-title border-l-4 left-[18px] absolute -bottom-8 " />
        </div>
        <div className="relative flex items-center space-x-6">
          <img src="/asama_04.png" alt="Asama 4" width={90} height={90} />
          <p className="font-bold lg:text-xl text-text">Fırsatlardan Haberdar Olmak İstersen <br />  E-posta ve SMS Bildirimlerine İzin Ver</p>
          <div className="hidden lg:block h-[32px] border-title border-l-4 left-[18px] absolute -bottom-8 " />
        </div>
        <div className="relative flex items-center space-x-6">
          <img src="/asama_05.png" alt="Asama 5" width={90} height={90} />
          <p className="font-bold lg:text-xl text-text">Üyeliğinin onaylanmasını bekle</p>
          <div className="hidden lg:block h-[32px] border-title border-l-4 left-[18px] absolute -bottom-8 " />
        </div>
        <div className="relative flex items-center space-x-6">
          <img src="/asama_06.png" alt="Asama 5" width={90} height={90} />
          <p className="font-bold lg:text-xl text-text">Üyelik İşlemini Tamamla!</p>
        </div>
      </div>

    </div>
  )
}

export default HowBecomeAMember