import React from 'react'
import { Outlet } from 'react-router'
import Footer from './Footer/Footer'

function Layout() {
    return (
        <div className="bg-background px-5 " >
            <div className="container mx-auto">
                <Outlet />
                <Footer />
            </div>
        </div>
    )
}

export default Layout
